import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/improving-next-js-performance",
  "date": "2021-12-05",
  "title": "Improving Next.js Performance",
  "author": "admin",
  "tags": ["development", "javascript", "nextjs", "performance"],
  "featuredImage": "feature.jpg",
  "excerpt": "Next.js is a zero-config React framework for building server-side and static web applications bundled with enhanced developer experience with features for products such as smart bundling, TypeScript support, route pre-fetching, hybrid static & server rendering, and more."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`If you have been using Next.js in production, you may want to explore a few different ways to improve your web application performance and get the best possible outcome from what Next.js provides you.`}</p>
    <h2>{`Table of Contents`}</h2>
    <ol>
      <li parentName="ol">{`Select the right rendering mode`}</li>
      <li parentName="ol">{`Defer loading non-critical scripts to load when the page is idle`}</li>
      <li parentName="ol">{`Image Optimization with the Image component`}</li>
      <li parentName="ol">{`Code-splitting client-side code to reduce initial bundle size`}</li>
      <li parentName="ol">{`Server-side rendering with React Server components (Beta)`}</li>
      <li parentName="ol">{`Faster build times with SWC`}</li>
    </ol>
    <h2>{`Select the right rendering mode`}</h2>
    <p>{`Next.js at the moment supports three different methods of rendering pages that are built into the framework. Understanding how these methods work will greatly help you build your web application for performance.`}</p>
    <p><strong parentName="p">{`Server-Rendering (SSR)`}</strong></p>
    <p>{`In response to each request, renders the app on the server, and then sends the rendered HTML and JavaScript back to the client.`}</p>
    <p><strong parentName="p">{`Client-Side Rendering (CSR)`}</strong></p>
    <p>{`Renders the app in the browser on the client at run time.`}</p>
    <p><strong parentName="p">{`Static Rendering (SR)`}</strong></p>
    <p>{`Usually generating a static HTML page for every URL. Pre-built app, or renders the app at build time (e.g. when you run the `}<em parentName="p">{`npm run build`}</em>{` or `}<em parentName="p">{`yarn build`}</em>{` command).`}</p>
    <p><strong parentName="p">{`React Server components`}</strong></p>
    <p>{`React Server Components which is available in React 18 beta, will render components entirely server-side, making it possible to have a zero-KB client-side bundle.`}</p>
    <p>{`Now let's look at the ways we can improve the performance of these methods.`}</p>
    <p><strong parentName="p">{`Server-Rendering (SSR)`}</strong></p>
    <p>{`For a given time, if the content is considered `}<em parentName="p">{`fresh`}</em>{` , you can add `}<inlineCode parentName="p">{`Cache-Control`}</inlineCode>{` headers to instruct Vercel/CDNs to cache the resulting HTML for a given period:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`export async function getServerSideProps({ query, res }) {
  res.setHeader(
    'Cache-Control',
    'public, s-maxage=43200, stale-while-revalidate=60'
  );

  const { title, body, imageUrl } = await fetch('YOUR_API_URL');

  return {
    props: {
      generatedAt: Date.new().toString(),
      title,
      body,
      imageUrl
    }
  };
}
`}</code></pre>
    <p><strong parentName="p">{`res.setHeader`}</strong>{` - This will cache the content of this page for 12 hours. After 12 hours, the content will continue to be served for a grace period of 60 seconds as new data is fetched. Then, the CDN will store a fresh copy for the next user.`}</p>
    <p>{`This will enable you to offer server-side rendered dynamic content much faster to the users.`}</p>
    <p><strong parentName="p">{`Static Rendering (SR)`}</strong></p>
    <p>{`This is the fastest way to load your website. Statically rendered pages are generated at the time your site is built. If your website doesn’t have a lot of content, and it doesn’t change between deployments, statically rendered pages will be the best option for you.`}</p>
    <p>{`This is the default rending method for Next.js when `}<inlineCode parentName="p">{`getServerSideProps`}</inlineCode>{` and `}<inlineCode parentName="p">{`getInitialProps`}</inlineCode>{` are not being used in your page components.`}</p>
    <p><strong parentName="p">{`Incremental static regeneration (ISR)`}</strong></p>
    <p>{`Incremental static regeneration is great for blogs and articles. If you publish content that can be rendered once per deployment, and it won’t change that often after, SR is the best option to go for.`}</p>
    <p>{`For instance, let's say you deploy your web application every time you create new content/blog pages, this is the ideal option. But if the publication process involves third-party CMS platforms like Sitecore and the publication process doesn't involve deploying your codebase, then SSR is the best option to dynamically load content.`}</p>
    <p>{`An ISR page differs by waiting until the browser requests the page before generating it. That process looks like this:`}</p>
    <ul>
      <li parentName="ul">{`User requests the page (e.g. /post).`}</li>
      <li parentName="ul">{`If this is the first request for the /post page since the last deployment, the server will generate and store the page in cache until the next deployment.`}</li>
      <li parentName="ul">{`Each following request to /post will then be served the pre-generated page from a cache.`}</li>
    </ul>
    <p>{`Following this approach in a content-heavy web application (with many hundreds or even thousands of pages or articles), you will be able to reduce build wait time significantly while still offering viewers lightening fast page renders.`}</p>
    <h3>{`To summarise the rendering methods:`}</h3>
    <ul>
      <li parentName="ul">{`For dynamic content or when the query parameters alter the rendered content of your page, use Server-Side Rendering (SSR).`}</li>
      <li parentName="ul">{`For scenarios where you have plenty of pages but don’t want to wait for them to be statically generated, use `}<a parentName="li" {...{
          "href": "https://vercel.com/docs/concepts/next.js/incremental-static-regeneration"
        }}>{`Incremental Static Regeneration (ISR)`}</a>{`.`}</li>
      <li parentName="ul">{`If any of the above use cases don’t apply, use Statically Rendered pages.`}</li>
    </ul>
    <p>{`Read more about different Next.js rendering methods here:  `}<a parentName="p" {...{
        "href": "https://szaranger.medium.com/nextjs-client-side-server-side-static-rendering-1836337998b9?source=your_stories_page----------------------------------------"
      }}>{`https://szaranger.medium.com/nextjs-client-side-server-side-static-rendering-1836337998b9`}</a></p>
    <h1>{`Defer loading non-critical scripts to load when the page is idle`}</h1>
    <p>{`Websites often use third-party scripts for analytics, ads, customer support widgets, and consent management. However, this can introduce problems that impact both user and developer experience:`}</p>
    <ul>
      <li parentName="ul">{`Some third-party scripts are heavy on loading performance and can drag down the user experience, especially if they are render-blocking and delay any page content from loading`}</li>
      <li parentName="ul">{`Developers often struggle to decide where to place third-party scripts in an application to ensure optimal loading`}</li>
    </ul>
    <p>{`The Script component makes it easier for developers to place a third-party script anywhere in their application while taking care of optimizing its loading strategy.`}</p>
    <p>{`With `}<inlineCode parentName="p">{`next/script`}</inlineCode>{`, you decide when to load your third-party script by using the `}<inlineCode parentName="p">{`strategy`}</inlineCode>{` property:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Script src="https://connect.facebook.net/en_US/sdk.js" strategy="lazyOnload" />
`}</code></pre>
    <p>{`There are three different loading strategies that can be used:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`beforeInteractive`}</inlineCode>{`: Load before the page is interactive`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`afterInteractive`}</inlineCode>{`: (`}<strong parentName="li">{`default`}</strong>{`): Load immediately after the page becomes interactive`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`lazyOnload`}</inlineCode>{`: Load during idle time`}</li>
    </ul>
    <p>{`If you’d like a script to have minimal impact on your page, use lazyOnload.`}</p>
    <h2>{`Image Optimization with the Image component`}</h2>
    <p>{`Next’s `}<a parentName="p" {...{
        "href": "https://nextjs.org/docs/basic-features/image-optimization"
      }}>{`Image`}</a>{` is a powerful component that simplifies the responsive image generation, optimized using compression algorithms.`}</p>
    <p>{`Here are some of the benefits of the Image component:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Built-in WebP and AVIF  support.`}</strong>{` The browser can display AVIF, WebP, PNG, SVG, or any other image-based MIME type by making the servers to be able to decipher which format to send via HTTP header. Eg: Accept: image/avif, image/webp, image/apng, image/svg+xml, image/`}<em parentName="li">{`,`}</em>{`/*;q=0.8.`}</li>
      <li parentName="ul">{`A`}<strong parentName="li">{`utomatically avoid Cumulative Layout Shifts`}</strong>{` by placing a blurred or transparent placeholder image until the image has loaded.`}</li>
      <li parentName="ul"><strong parentName="li">{`Responsive images are resized on the fly`}</strong>{`, able to be cached by CDNs.`}</li>
      <li parentName="ul"><strong parentName="li">{`It’s adaptable to responsive design.`}</strong>{` The layout attribute allows you to specify images that should scale to fit or fill their containers.`}</li>
    </ul>
    <p>{`You can enable WebP and AVIF by adding them to the to next.config.js:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`{
  images: {
    formats: ['image/webp', 'image/avif']
  }
}
`}</code></pre>
    <p>{`By offering AVIF images to supported clients, you can reduce large detailed blog illustrations by 60% for large desktop displays.`}</p>
    <p>{`Next.js’ Image component provides the power and flexibility that most pages require and is a solid upgrade on base functionality provided by native `}<img />{` elements.`}</p>
    <p>{`Read more about the options here: `}<a parentName="p" {...{
        "href": "https://nextjs.org/docs/api-reference/next/image"
      }}>{`https://nextjs.org/docs/api-reference/next/image`}</a></p>
    <h2>{`Code-splitting `}<strong parentName="h2">{`client-side code to reduce initial bundle size`}</strong></h2>
    <p>{`Code-splitting using dynamic imports, we can keep the Next.js bundle sizes small.`}</p>
    <p>{`Code-splitting allows you to lazily-load dependencies or application code, as required by the user. This is done by splitting JavaScript bundles into smaller chunks which are small JavaScript files. `}</p>
    <p>{`This helps us to accomplish the following:`}</p>
    <ul>
      <li parentName="ul">{`Faster initial page loads`}</li>
      <li parentName="ul">{`Only load what’s required, so fewer to download`}</li>
      <li parentName="ul">{`Fewer JavaScript to parse, compile and execute helping slower mobile devices`}</li>
      <li parentName="ul">{`Chunks can be cached individually`}</li>
    </ul>
    <p>{`In the example below, we are heavily reducing initial JavaScript bundle size by dynamically loading the Ad component, keeping them from appearing in the main JavaScript bundle:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// Before
import Ad from 'components/Ad';

// After
import dynamic from 'next/dynamic';

const Ad = dynamic(() => import('components/Ad'));
`}</code></pre>
    <p>{`To see a complete list of options for dynamic loading, see the `}<a parentName="p" {...{
        "href": "https://nextjs.org/docs/advanced-features/dynamic-import"
      }}>{`official Next.js documentation.`}</a></p>
    <h2>{`Server-side rendering with React Server components (Beta)`}</h2>
    <p>{`By using Server Components, you will be able to opt-in which parts of your application are rendered on the server-side and when client-side code is required. In Next.js, a server component will be denoted by filename, e.g., post.server.js, whereas a client-side component will use post.client.js.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// pages/[post].server.js
import React, { Suspense } from 'react';
import dynamic from 'next/dynamic';

import Layout from 'components/Layout.server.js';
import Content from 'components/Content.server.js';
import { getContent } from 'data';

const Ad = dynamic(() => import('components/Ad.client.js'));

export default function Post({ slug }) {
  const { title, createdDate, content } = getContent({ slug: slug });

  return (
    <Layout>
        <article>
          <header>
            <h1>{title}</h1>
            <p>Published: {createdDate}</p>
          </header>
    
          <Suspense>
            <Ad />
          </Suspense>
    
          <Content source={content} />
        </article>
    <Layout>
  );
}
`}</code></pre>
    <p>{`In the above example, we fetch content from a static data folder or a CMS and render markdown. The resulting client-side JavaScript from this page will only include the Ad, which is dynamically loaded. This way, you can first deliver server-rendered content, then enhance the page with client-side components, and then React will resolve Suspense boundaries client-side.`}</p>
    <p>{`Learn more about `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://szaranger.medium.com/server-side-rendering-with-suspense-in-react-18-123988a7b39e?source=your_stories_page----------------------------------------"
        }}>{`Server Side Rendering with Suspense in React 18`}</a></strong></p>
    <h2>{`Faster build times with SWC`}</h2>
    <p>{`Next.js uses webpack and Babel under the hood to compile JavaScript bundles. Babel using a JavaScript runtime and has been slow to compile.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ next build
`}</code></pre>
    <p>{`With the arrrival of `}<a parentName="p" {...{
        "href": "https://swc.rs/"
      }}>{`SWC`}</a>{`, a new Rust based compiler for JavaScript, which has superior compilation speeds, `}<a parentName="p" {...{
        "href": "https://nextjs.org/blog/next-12#faster-builds-and-fast-refresh-with-rust-compiler"
      }}>{`Next.js 12 announced SWC`}</a>{` as it's new experimental JS bundler. This means that next build, live-reloads and deployments will all be faster.`}</p>
    <p>{`In order to kick-in SWC as the compiler, make sure to remove the babel.config.js file.`}</p>
    <h3>{`Summary`}</h3>
    <p>{`To improve your web application performance and get the best possible outcome from what Next.js provides you, we can:`}</p>
    <ol>
      <li parentName="ol">{`Select the right rendering mode`}</li>
      <li parentName="ol">{`Defer loading non-critical scripts to load when the page is idle`}</li>
      <li parentName="ol">{`Image Optimization with the Image component`}</li>
      <li parentName="ol">{`Code-splitting client-side code to reduce initial bundle size`}</li>
      <li parentName="ol">{`Server-side rendering with React Server components (Beta)`}</li>
      <li parentName="ol">{`Faster build times with SWC`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      